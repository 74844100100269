<div>
  <form [formGroup]="eventFormGroup" class="tw-flex tw-flex-col tw-gap-4">
    <label htmlFor="username" class="">Titolo</label>
    <input class="-tw-mt-2" pInputText formControlName="title" />
    <label htmlFor="username">Sotto titolo</label>
    <input class="-tw-mt-2" pInputText formControlName="subtitle" />
    <label htmlFor="username">Descrizione</label>
    <textarea class="-tw-mt-2" rows="5" cols="30" pInputTextarea formControlName="description"></textarea>
    <label htmlFor="username">Data</label>
    <p-calendar class="-tw-mt-2" appendTo="body" formControlName="date" dateFormat="yy.mm.dd"></p-calendar>
  </form>
  <div class="tw-mt-8 tw-flex tw-flex-row tw-justify-end tw-gap-4">
    <p-button icon="pi pi-times" label="Annulla" styleClass="p-button-link p-button-sm" (click)="close()">
    </p-button>
    <p-button icon="pi pi-check" (click)="saveEvent()" label="{{event?'Modifica':'Crea'}}" styleClass="p-button-sm"
      [loading]="loading">
    </p-button>
  </div>
</div>