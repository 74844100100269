<app-dea-container>
  <div class="w-100 tw-flex tw-justify-center tw-mt-20 tw-opacity-50">
    <app-dea-warning-alert [title]="'ATTENZIONE!'">
      <div class="tw-mt-1">Sembra si sia verificato un errore con la tua sessione</div>
      <div class="tw-mt-0">Ripulisco la sessione e tra 10 secondi ti riporto alla Homepage</div>
      <div class="tw-text-sm tw-mt-5">
        Avvisa l'admin che si è verificato questo problema descrivendo la dinamica delle operazioni che hai
        eseguito
      </div>
    </app-dea-warning-alert>
  </div>
</app-dea-container>