import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { EventService } from 'src/app/services/event.service';
import { AuthService } from 'src/app/services/auth.service';
import { AppPaths } from 'src/app/constants/app-paths';
import { Event } from 'src/app/models/event.model';
import { API } from 'src/app/constants/api';
import { Router } from '@angular/router';
import { io } from 'socket.io-client';

@Component({
  selector: 'app-presenze',
  templateUrl: './presenze.component.html',
  styleUrls: ['./presenze.component.scss'],
})
export class PresenzeComponent implements OnInit, OnDestroy {
  private _authService: AuthService = inject(AuthService);
  private _eventService: EventService = inject(EventService);
  private socket = io(API.evnetSocket(), {
    withCredentials: true,
    secure: true,
  });

  event?: Event;
  index?: number;
  attempt: number = 0;
  onRefresh: boolean = false;
  loading: boolean = true;

  constructor(private _router: Router) {}

  get attendees() {
    return this.event?.attendees ? this.event.attendees : [];
  }

  ngOnInit(): void {
    this.getData(true);
  }

  ngOnDestroy(): void {
    this.socket.close();
  }

  setSocketConnection() {
    this.socket.on('connect', () => console.info('Socket: Connected!'));
    this.socket.on('disconnect', () => console.info('Socket: Disconnect!'));
    this.socket.on('connect_error', (error: any) => {
      console.error('Socket:', error.message, '!');
      console.info('Socket attempt:', this.attempt, '!');
      if (this.onRefresh || this.attempt > 10) this.showSessionError();
      this.onRefresh = true;
      this.attempt += 1;
      this._authService.token().subscribe({
        next: () => {
          this.socket.disconnect();
          this.socket.connect();
          this.attempt = 0;
          console.info('Socket: Refreshed!');
          this.patchAttendeeIndex();
        },
        error: (err: any) => {
          console.error('Session-Refresh Error on Socket Conn Error:', err);
          this.showSessionError();
        },
      });
    });
    this.socket.on('update-attendee', (data: any) => {
      const index = this.event?.attendees?.findIndex((a) => a.id === data._id);
      if (index != -1 && index != undefined && this.event?.attendees) {
        this.event.attendees[index].status = data.status;
      }
    });
  }

  getData(createSocket: boolean = false): void {
    this.loading = true;
    this.event = undefined;
    this._eventService
      .getPublicEventDetails()
      .subscribe({
        next: (data: any) => {
          this.event = new Event(data.data);
          if (createSocket) this.setSocketConnection();
        },
        error: (err: any) => {
          this.event = undefined;
          console.error('Get Public Event Details: ', err);
        },
      })
      .add(() => (this.loading = false));
  }

  setIndexAndPatch(index: number): void {
    this.index = index;
    this.patchAttendeeIndex();
  }

  patchAttendeeIndex(): void {
    if (this.index != undefined && this.event?.attendees) {
      var attendee = { ...this.event?.attendees[this.index] };
      attendee.status = attendee.status ? false : true;
      this.socket.emit('patch-attendee', {
        id: this.event.id || 'ID-ERROR',
        attendeeId: attendee.id || 'ID-ERROR',
        status: !attendee.status || false,
      });
      this.onRefresh = false;
    }
  }

  showSessionError(): void {
    // DISCONNETTO L'UTENTE PER ERRORE DI SESSIONE
    this._router.navigateByUrl(`/${AppPaths.SESSION_ERROR}`);
  }
}
