import { EventDialogComponent } from 'src/app/components/event-dialog/event-dialog.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EventService } from 'src/app/services/event.service';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Component, OnInit, inject } from '@angular/core';
import { Event } from 'src/app/models/event.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  private _dialogService: DialogService = inject(DialogService);
  private _eventService: EventService = inject(EventService);
  ref: DynamicDialogRef | undefined;

  events: Event[] = [];

  error?: ApiError;
  loading: boolean = true;
  visible: boolean = false;
  posting: boolean = false;

  eventFormGroup: FormGroup = new FormGroup({
    title: new FormControl(undefined, [
      Validators.required,
      Validators.minLength(4),
    ]),
    subtitle: new FormControl(undefined),
    description: new FormControl(undefined),
    date: new FormControl(undefined),
  });

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    this.loading = true;
    this.error = undefined;
    this._eventService
      .getEvents()
      .subscribe({
        next: (data: any) => {
          try {
            this.events = data?.data?.map((event: any) => new Event(event));
          } catch (e) {
            this.events = [];
            console.error('Map Error:', e);
          }
        },
        error: (err: any) => {
          this.error = new ApiError(err);
          console.error('GetEvents Error:', err);
        },
      })
      .add(() => (this.loading = false));
  }

  newEventDialog() {
    this.ref = this._dialogService.open(EventDialogComponent, {
      width: '95vw',
      maximizable: false,
      header: 'Nuovo Evento',
      style: { 'max-width': '500px' },
      baseZIndex: 10000,
      dismissableMask: true,
      closeOnEscape: true,
      closable: false,
      modal: true,
    });

    this.ref.onClose.subscribe((value: boolean) => {
      if (value != undefined) {
        value ? this.getData() : console.error('Errore');
      }
    });
  }
}
