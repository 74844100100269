<div>
  <input id="fix-autofocus" class="tw-mt-0" pInputText hidden autofocus />
  <div class="tw-flex tw-flex-col tw-gap-4">
    <div *ngFor="let message of messages">{{message}}</div>
  </div>
  <div class="tw-mt-8 tw-flex tw-flex-row tw-justify-end tw-gap-4">
    <p-button icon="pi pi-times" label="Annulla" styleClass="p-button-link p-button-sm" (click)="close(false)"
      [autofocus]="false">
    </p-button>
    <p-button icon="pi pi-trash" (click)="close(true)" label="Elimina" styleClass="p-button-sm p-button-danger"
      [autofocus]="false">
    </p-button>
  </div>
</div>