import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AppPaths } from '../constants/app-paths';
import { inject } from '@angular/core';

export const authGuard: CanActivateFn = (route, state) => {
  const _DEBUG_MODE: boolean = false;
  const router: Router = inject(Router);
  const authService = inject(AuthService);
  if (_DEBUG_MODE) console.log('AUTH GUARD! => ', authService.isLogged);
  if (authService.isLogged === true) {
    if (_DEBUG_MODE) console.log("AUTH GUARD! => LET'S GO!");
    return true;
  } else if (authService.isLogged === false) {
    if (_DEBUG_MODE) console.log('AUTH GUARD! => HOMEPAGE');
    return router.navigateByUrl(`/${AppPaths.HOMEPAGE}`);
  }
  if (_DEBUG_MODE) console.log('AUTH GUARD! => SESSION');
  return router.navigateByUrl(`/${AppPaths.SESSION}?next=/pin`);
};
