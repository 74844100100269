import { Headquarter } from './headquarter';
import { Attendee } from './attendee.model';

export class Event {
  id?: string;
  headquarter?: Headquarter;
  public?: boolean;
  title?: string;
  subtitle?: string;
  description?: string;
  date?: Date;
  attendees?: Attendee[];

  constructor(json?: any) {
    if (json) {
      this.id = json['_id'] || json['id'];
      // this.headquarter = json['headquarter']; // TODO: FIXIT!
      this.public =
        json['public'] === undefined
          ? undefined
          : (json['public'] + '').toString().toLowerCase().trim() === 'true'
          ? true
          : false;
      this.title = json['title'];
      this.subtitle = json['subtitle'];
      this.description = json['description'];
      this.date = json['date'];
      this.attendees = (json['attendees'] || []).map((attendee: any) => {
        return Attendee.fromJson(attendee);
      });
    }
  }
}
