import { ApiResponse } from '../models/api/api-response.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor(private http: HttpClient) {}

  getPublicEvent() {
    return this.http.get<ApiResponse>(API.publicEvent(), {
      withCredentials: false,
    });
  }

  getPublicEventDetails() {
    return this.http.get<ApiResponse>(API.publicEventDetails(), {
      withCredentials: true,
    });
  }

  getEvents() {
    return this.http.get<ApiResponse>(API.events(), {
      withCredentials: true,
    });
  }

  postEvent(data: {
    title: string;
    subtitle?: string;
    description?: string;
    date?: Date;
  }) {
    return this.http.post<ApiResponse>(API.event(), data, {
      withCredentials: true,
    });
  }

  patchEvent(
    id: string,
    data: {
      title: string;
      subtitle?: string;
      description?: string;
      date?: Date;
    }
  ) {
    return this.http.patch<ApiResponse>(API.eventId(id), data, {
      withCredentials: true,
    });
  }

  postCSV(id: string, data: any, reset: boolean) {
    return this.http.post<ApiResponse>(API.eventCSV(id, reset), data, {
      withCredentials: true,
    });
  }

  getCSV(id: string) {
    return this.http.get(API.eventCSV(id, false), {
      observe: 'response',
      responseType: 'blob',
      withCredentials: true,
    });
  }

  patchEventPublic(p: { id?: string; public: boolean }) {
    return this.http.patch<ApiResponse>(
      API.patchPublicValueEvent(p.id),
      {},
      {
        params: new HttpParams().set('set', p.public),
        withCredentials: true,
      }
    );
  }

  getEventDetails(id?: string) {
    return this.http.get<ApiResponse>(API.eventId(id), {
      withCredentials: true,
    });
  }

  deleteEvent(id?: string) {
    return this.http.delete<ApiResponse>(API.eventId(id), {
      withCredentials: true,
    });
  }
}
