export class AppPaths {
  // AUTH
  static SESSION_ERROR: string = 'session-error';
  static SESSION: string = 'session';
  static LOGIN: string = 'login';
  static LOGOUT: string = 'logout';
  static PIN: string = 'pin';
  // APP
  static HOMEPAGE: string = '';
  static PRESENZE: string = 'presenze';
  static DASHBOARD: string = 'dashboard';
  static EVENT_DETAILS: string = 'event';
  // UTILITY
  static NOT_FOUND: string = 'not-found';
  static ALL: string = '**';
}
