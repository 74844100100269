export class Attendee {
  id?: string;
  status?: boolean;
  lastname?: string;
  firstname?: string;
  email?: string;
  phone?: string;
  metadata?: Object;

  public static fromJson(json: any): Attendee {
    let newAttendee = new Attendee();
    newAttendee.id = json['_id'] || json['id'];
    newAttendee.status =
      json['status'] === undefined
        ? undefined
        : (json['status'] + '').toString().toLowerCase().trim() === 'true'
        ? true
        : false;
    newAttendee.lastname = json['lastname'];
    newAttendee.firstname = json['firstname'];
    newAttendee.email = json['email'];
    newAttendee.phone = json['phone'];
    newAttendee.metadata = json['metadata'];
    return newAttendee;
  }
}
