import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppPaths } from 'src/app/constants/app-paths';
import { Component } from '@angular/core';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss'],
})
export class SessionComponent {
  private _DEBUG_MODE: boolean = false;
  private _nextRoute: string | null;

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _activatedRoute: ActivatedRoute
  ) {
    this._nextRoute = this._activatedRoute.snapshot.queryParamMap.get('next');
  }
  ngOnInit(): void {
    const isAdmin: boolean = this._nextRoute === '/login';
    if (this._DEBUG_MODE) console.log('Session Component => isAdmin:', isAdmin);
    this._authService.session(isAdmin).subscribe({
      next: (value: any) => {
        if (this._DEBUG_MODE) console.log('VALUE => ', value);
        this._authService.isLogged = true;
        switch (this._nextRoute) {
          case '/login':
            this._authService.isAdmin = true;
            return this.next(`/${AppPaths.DASHBOARD}`);
          case '/pin':
            this._authService.isAdmin = false;
            return this.next(`/${AppPaths.PRESENZE}`);
          default:
            this.next(`/${AppPaths.HOMEPAGE}`);
        }
      },
      error: (err: any) => {
        if (this._DEBUG_MODE) console.log('ERROR => ', err);
        this._authService.isAdmin = true;
        this._authService.isLogged = true;
        switch (this._nextRoute) {
          case '/login':
            return this.next(`/${AppPaths.LOGIN}`);
          case '/pin':
            return this.next(`/${AppPaths.PIN}`);
          default:
            this.next(`/${AppPaths.HOMEPAGE}`);
        }
      },
    });
  }

  next(url: string): void {
    setTimeout(() => this._router.navigateByUrl(url), 1000);
    if (this._DEBUG_MODE) console.log('Session Next => url:', url);
  }
}
