import { Component } from '@angular/core';

@Component({
  selector: 'app-user-cell',
  templateUrl: './user-cell.component.html',
  styleUrls: ['./user-cell.component.scss']
})
export class UserCellComponent {

}
