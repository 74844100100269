import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// GUARDS
import { authAdminGuard } from './guards/auth-admin.guard';
import { authGuard } from './guards/auth.guard';
// PAGES
import { AppPaths } from './constants/app-paths';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { PresenzeComponent } from './pages/presenze/presenze.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SessionComponent } from './pages/session/session.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { LoginComponent } from './pages/login/login.component';
import { PinComponent } from './pages/pin/pin.component';
import { SessionErrorComponent } from './pages/session-error/session-error.component';
import { EventDetailsComponent } from './pages/dashboard/event-details/event-details.component';

const baseTitle = 'De Amicis Ring';

const routes: Routes = [
  {
    path: AppPaths.HOMEPAGE,
    pathMatch: 'full',
    title: 'Events - ' + baseTitle,
    component: HomepageComponent,
  },
  {
    path: AppPaths.SESSION_ERROR,
    title: 'Session Error - ' + baseTitle,
    component: SessionErrorComponent,
  },
  {
    path: AppPaths.SESSION,
    title: 'Session - ' + baseTitle,
    component: SessionComponent,
  },
  {
    path: AppPaths.LOGIN,
    title: 'Login - ' + baseTitle,
    component: LoginComponent,
  },
  {
    path: AppPaths.PIN,
    title: 'PIN - ' + baseTitle,
    component: PinComponent,
  },
  {
    path: AppPaths.LOGOUT,
    title: 'Logout - ' + baseTitle,
    component: LogoutComponent,
  },
  {
    path: AppPaths.PRESENZE,
    canActivate: [authGuard],
    title: 'Presenze - ' + baseTitle,
    component: PresenzeComponent,
  },
  {
    path: AppPaths.DASHBOARD,
    canActivate: [authAdminGuard],
    title: 'Dashboard - ' + baseTitle,
    component: DashboardComponent,
  },
  {
    path: AppPaths.DASHBOARD + '/' + AppPaths.EVENT_DETAILS + '/:id',
    canActivate: [authAdminGuard],
    title: 'Event Details - ' + baseTitle,
    component: EventDetailsComponent,
  },
  {
    path: AppPaths.NOT_FOUND,
    component: NotFoundComponent,
    title: '404 - ' + baseTitle,
  },
  { path: AppPaths.ALL, redirectTo: AppPaths.NOT_FOUND },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
