import { EventService } from 'src/app/services/event.service';
import { Component, Input, inject } from '@angular/core';
import { Event } from 'src/app/models/event.model';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-event-details-box',
  templateUrl: './event-details-box.component.html',
  styleUrls: ['./event-details-box.component.scss'],
})
export class EventDetailsBoxComponent {
  private _messageService: MessageService = inject(MessageService);
  private _eventService: EventService = inject(EventService);
  @Input() showSwitch: boolean = false;
  @Input() showLine: boolean = true;
  @Input() event?: Event;

  get switchValue(): boolean {
    return this.event?.public != undefined ? this.event.public : false;
  }

  onSwitchChanged(event: any) {
    this._eventService
      .patchEventPublic({
        id: this.event?.id,
        public: event.checked,
      })
      .subscribe({
        next: (_) => {
          this._messageService.add({
            key: 'app-toast',
            severity: 'success',
            summary: 'Ok!',
            detail: 'Evento aggiornato correttamente.',
          });
        },
        error: (err) => {
          console.error('Patch public value error:', err);
          this._messageService.add({
            key: 'app-toast',
            severity: 'error',
            summary: 'Attenzione:',
            detail:
              "Sembra si sia verificato un errore in fase di aggiornamento dell'evento, riprova più tardi.",
          });
        },
      });
  }
}
