import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';

interface LoginInterface {
  username: string;
  password: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private audienceBase: string = 'EMS-BASE';
  private audienceAdmin: string = 'EMS-ADMIN';

  test: boolean | undefined = undefined;
  isAdmin: boolean | undefined = undefined;
  isLogged: boolean | undefined = undefined;

  constructor(private http: HttpClient) {}

  login(credentials: LoginInterface, isAdmin: boolean = false) {
    return this.http.post(API.login(), credentials, {
      withCredentials: true,
      responseType: 'json',
      headers: {
        'X-Auth-Mode': 'cookie',
        'Content-Type': 'application/json',
        Audience: isAdmin ? this.audienceAdmin : this.audienceBase,
      },
    });
  }

  logout() {
    return this.http.get(API.logout(), {
      withCredentials: true,
      responseType: 'json',
      headers: {
        'X-Auth-Mode': 'cookie',
        'Content-Type': 'application/json',
      },
    });
  }

  token() {
    return this.http.get(API.token(), {
      withCredentials: true,
      responseType: 'json',
      headers: {
        'X-Auth-Mode': 'cookie',
        'Content-Type': 'application/json',
      },
    });
  }

  session(isAdmin: boolean) {
    return this.http.get(API.session(), {
      withCredentials: true,
      responseType: 'json',
      headers: {
        'X-Auth-Mode': 'cookie',
        'Content-Type': 'application/json',
        Audience: isAdmin ? this.audienceAdmin : this.audienceBase,
      },
    });
  }
}
