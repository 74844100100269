<div title="Dettagli: {{event?.title}}" routerLink="/dashboard/event/{{event?.id}}"
  class="tw-w-full tw-min-h-[150px] tw-flex tw-flex-col tw-justify-between tw-border tw-border-neutral-100 tw-rounded tw-shadow tw-p-4 tw-cursor-pointer dea-scale-animation">
  <div>
    <div class="tw-flex tw-flex-row tw-justify-between">
      <div class="tw-font-medium">{{event?.title}}</div>
      <div class="tw-font-medium">
        <lucide-icon *ngIf="event?.public == true" name="star" class="tw-text-deaOrange" [size]="16" [strokeWidth]="1">
        </lucide-icon>
        <lucide-icon *ngIf="event?.public == false" name="star" class="tw-text-deaOrange tw-opacity-50" [size]="16"
          [strokeWidth]="1">
        </lucide-icon>
      </div>
    </div>
    <div class="tw-font-normal">{{event?.date | toItDateDdMmmmYyyy}}</div>
  </div>
  <div class="tw-flex tw-justify-between tw-content-end tw-h-full">
    <div class="tw-text-5xl">{{event?.attendees?.length}}</div>
    <div class="tw-flex tw-justify-end tw-flex-col tw-h-[3rem]">
      <div class="tw-flex tw-flex-nowrap tw-font-light group detail-btn tw-opacity-75 hover:tw-opacity-100">
        <lucide-icon name="chevron-right" class="tw-opacity-80 group-hover:tw-opacity-100" [strokeWidth]="2">
        </lucide-icon>
      </div>
    </div>
  </div>
</div>