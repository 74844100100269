<div class="tw-h-screen tw-flex tw-justify-center tw-items-center oops-background">
  <div>
    <h1 id="oops-text" class="tw-text-center">Oops!</h1>
    <h2 class="tw-text-center">Quasta pagina non è stata trovata...</h2>
    <div class="tw-flex tw-justify-center tw-items-center tw-mt-5">
      <p-button routerLink="/{{linkAction}}">
        <div class="tw-px-10 tw-py-1 tw-font-bold">Home</div>
      </p-button>
    </div>
  </div>
</div>