import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiError } from 'src/app/models/api/api-error.model';
import { AuthService } from 'src/app/services/auth.service';
import { AppPaths } from 'src/app/constants/app-paths';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  error?: ApiError;
  loading: boolean = false;
  loginForm: FormGroup = new FormGroup({
    username: new FormControl(null, [
      Validators.required,
      Validators.minLength(6),
    ]),
    password: new FormControl(null, [
      Validators.required,
      Validators.minLength(8),
    ]),
  });

  constructor(private _authService: AuthService, private _router: Router) {}

  onUsernameBlur() {
    if (this.loginForm.value.username) {
      let username: string = this.loginForm.value.username;
      const regex = new RegExp('^[a-zA-Z]{2}[0-9]{4}$', 'gm');
      if (regex.test(username)) {
        username = username.toString().trim().toUpperCase();
      } else if (username.includes('@')) {
        username = username.toString().trim().toLowerCase();
      } else {
        username = username.toString().trim();
      }
      this.loginForm.setValue({
        username: username,
        password: this.loginForm.value.password,
      });
    }
  }

  async submit() {
    // TODO: VERIFICARE GESTIONE ERRORI (403)
    this.loading = true;
    this.error = undefined;
    this.loginForm.disable({ onlySelf: this.loading });
    this._authService.login(this.loginForm.value, true).subscribe({
      next: (value: any) => {
        console.info('Login:', value);
        this._authService.isAdmin = true;
        this._authService.isLogged = true;
        setTimeout(() => {
          this._router.navigateByUrl(`/${AppPaths.DASHBOARD}`);
        }, 1000);
      },
      error: (err: any) => {
        this.error = new ApiError(err);
        this._authService.isAdmin = false;
        this._authService.isLogged = false;
        this.loginForm.setValue({ username: null, password: null });
        this.loginForm.clearValidators();
        this.loginForm.enable();
        this.loading = false;
      },
    });
  }
}
