<app-dea-full-screen-logo-wrapper [subtitle]="'Accesso gestione eventi'" [showHomepageBtn]="true">
  <form class="tw-max-w-full" [formGroup]="loginForm">
    <div class="p-fluid tw-flex tw-flex-col tw-gap-4">
      <div class="field">
        <input type="text" placeholder="Username" pInputText formControlName="username" (blur)="onUsernameBlur()" />
      </div>
      <div class="field">
        <p-password type="text" placeholder="Password" formControlName="password" [feedback]="false" [toggleMask]="true"
          (keydown.enter)="submit()">
        </p-password>
      </div>
      <div *ngIf="error?.error?.message" class="fs075rem tw-text-red-500 -tw-mt-2 tw-ms-2">
        {{error?.error?.message}}
      </div>
      <div>
        <p-button label="Login" (onClick)="submit()" [loading]="loading"></p-button>
      </div>
    </div>
  </form>
</app-dea-full-screen-logo-wrapper>