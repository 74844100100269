<app-navbar></app-navbar>
<!-- ADD BTN -->
<div class="tw-z-10 tw-fixed tw-bottom-6 tw-right-6 tw-bg-deaOrange tw-shadow tw-p-2 tw-rounded-full tw-cursor-pointer">
  <lucide-icon *ngIf="loading" name="plus" [size]="32" class="tw-text-white tw-opacity-75 tw-cursor-default" disabled>
  </lucide-icon>
  <lucide-icon *ngIf="!loading" name="plus" [size]="32" class="tw-text-white" title="Crea Evento"
    (click)="newEventDialog()">
  </lucide-icon>
</div>
<!-- ADD BTN -->
<!-- EVENTS -->
<app-dea-container>
  <div *ngIf="loading">
    <app-dea-spinner [sizePx]="'32'"></app-dea-spinner>
  </div>
  <div *ngIf="!loading && events.length == 0" class="w-100 tw-flex tw-justify-center tw-mt-20 tw-opacity-50">
    <app-dea-warning-alert [title]="'Nessun evento da mostrare, per il momento...'">
      <div class="tw-text-sm tw-mt-1">Creane uno nuovo premendo sul pulsante in basso a destra!</div>
    </app-dea-warning-alert>
  </div>
  <div *ngIf="!loading && events.length != 0"
    class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-6">
    <app-event-card *ngFor="let event of events;" [event]="event"></app-event-card>
  </div>
</app-dea-container>
<!-- EVENTS -->