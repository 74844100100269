import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiError } from 'src/app/models/api/api-error.model';
import { AuthService } from 'src/app/services/auth.service';
import { AppPaths } from 'src/app/constants/app-paths';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.scss'],
})
export class PinComponent implements OnInit {
  protected sessionUsername: string = 'faf4033c-f922-4d85-8dc1-60eaa6dccdb5';
  error?: ApiError;
  loading: boolean = false;
  btnBlocks: string[] = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'A',
    'B',
  ];

  pinForm: FormGroup = new FormGroup({
    password: new FormControl(null, [
      Validators.required,
      Validators.minLength(8),
    ]),
  });

  constructor(private _authService: AuthService, private _router: Router) {}

  ngOnInit(): void {
    this.pinForm.disable();
  }

  setPinValue(value: string | null) {
    this.pinForm.setValue({ password: value });
  }

  addCharacter(character: string) {
    this.setPinValue((this.pinForm.get('password')?.value ?? '') + character);
  }

  clearCharacters() {
    this.setPinValue(null);
    this.error = undefined;
  }

  submit() {
    this.error = undefined;
    this.loading = true;
    this._authService
      .login(
        Object.assign({ username: this.sessionUsername }, this.pinForm.value),
        false
      )
      .subscribe({
        next: (value: any) => {
          console.info('Pin:', value);
          this._authService.isAdmin = false;
          this._authService.isLogged = true;
          setTimeout(() => {
            this.clearForm();
            this._router.navigateByUrl(`/${AppPaths.PRESENZE}`);
          }, 1000);
        },
        error: (err: any) => {
          this.clearForm();
          this.error = new ApiError(err);
          this._authService.isAdmin = false;
          this._authService.isLogged = false;
          this.loading = false;
        },
      });
  }

  clearForm(): void {
    this.pinForm.setValue({ password: null });
    this.pinForm.clearValidators();
  }
}
