<div>
  <!-- <form [formGroup]="eventFormGroup" class="tw-flex tw-flex-col tw-gap-4"> -->
  <div class="tw-flex tw-flex-col tw-gap-2 tw-mb-4">
    <label htmlFor="username">Elenco CSV</label>
    <p-fileUpload mode="basic" name="file" (onSelect)="onFileSelect($event)"></p-fileUpload>
  </div>
  <div class="tw-flex tw-flex-row tw-justify-between tw-mb-2">
    <label htmlFor="username">Sovrascrivi elenco:</label>
    <p-inputSwitch [ngModel]="reset" (onChange)="onResetSwitch($event)"></p-inputSwitch>
  </div>
  <div>
    Attenzione: abilitando l'opzione per sovrascrivere i dati l'elenco relativo a questo evento sarà eliminato e non
    sarà possibile recuperarlo. Con l'opzione disabilitata i dati saranno conservati e il nuovo elenco sarà aggiunto ai
    nuovi dati.
  </div>
  <!-- </form> -->
  <div class="tw-mt-8 tw-flex tw-flex-row tw-justify-end tw-gap-4">
    <p-button icon="pi pi-times" label="Annulla" styleClass="p-button-link p-button-sm" (click)="close()">
    </p-button>
    <p-button icon="pi pi-check" (click)="postCSV()" label="Carica" styleClass="p-button-sm" [loading]="loading">
    </p-button>
  </div>
</div>