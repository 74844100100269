import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EventService } from 'src/app/services/event.service';
import { Event } from 'src/app/models/event.model';
import { Component, inject } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-event-dialog',
  templateUrl: './event-dialog.component.html',
  styleUrls: ['./event-dialog.component.scss'],
})
export class EventDialogComponent {
  private _messageService: MessageService = inject(MessageService);
  private _eventService: EventService = inject(EventService);
  loading: boolean = false;
  event?: Event;

  eventFormGroup: FormGroup = new FormGroup({
    title: new FormControl(undefined, [
      Validators.required,
      Validators.minLength(4),
    ]),
    subtitle: new FormControl(undefined),
    description: new FormControl(undefined),
    date: new FormControl(undefined),
  });

  constructor(
    public ref: DynamicDialogRef,
    private _dynamicDialogConfig: DynamicDialogConfig
  ) {
    this.event = this._dynamicDialogConfig?.data?.event || undefined;
    if (this.event) {
      this.eventFormGroup.setValue({
        title: this.event?.title,
        subtitle: this.event?.subtitle,
        description: this.event?.description,
        date: this.event?.date ? new Date(this.event.date?.toString()) : null,
      });
    }
  }

  saveEvent() {
    this.loading = true;
    const data = this.eventFormGroup.value;
    if (this.event?.id) {
      this._eventService.patchEvent(this.event.id, data).subscribe({
        next: (_: any) => {
          this._messageService.add({
            key: 'app-toast',
            severity: 'success',
            summary: 'Ok!',
            detail: 'Evento aggiornato con successo.',
          });
          this.close(true);
        },
        error: (err: any) => {
          console.error('PatchEvent Error:', err);
          this._messageService.add({
            key: 'app-toast',
            severity: 'error',
            summary: 'Attenzione:',
            detail:
              "Sembra si sia verificato un errore in fase di aggiornamento dell'evento, riprova più tardi.",
          });
          this.close(false);
        },
      });
    } else {
      this._eventService.postEvent(data).subscribe({
        next: (_: any) => {
          this._messageService.add({
            key: 'app-toast',
            severity: 'success',
            summary: 'Ok!',
            detail: 'Nuovo evento creato con successo.',
          });
          this.close(true);
        },
        error: (err: any) => {
          console.error('PostEvent Error:', err);
          this._messageService.add({
            key: 'app-toast',
            severity: 'error',
            summary: 'Attenzione:',
            detail:
              'Sembra si sia verificato un errore in fase di creazione del nuovo evento, riprova più tardi.',
          });
          this.close(false);
        },
      });
    }
  }

  close(value?: boolean) {
    this.eventFormGroup.clearValidators();
    this.eventFormGroup.setValue({
      title: null,
      subtitle: null,
      description: null,
      date: null,
    });
    this.loading = false;
    this.ref.close(value);
  }
}
