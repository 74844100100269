<app-navbar></app-navbar>
<app-dea-container>
  <div *ngIf="loading" class="w-100 tw-flex tw-justify-center tw-mt-20 tw-opacity-50">
    <app-dea-spinner [sizePx]="'32'"></app-dea-spinner>
  </div>
  <div *ngIf="!loading && !event" class="w-100 tw-flex tw-justify-center tw-mt-20 tw-opacity-50">
    <app-dea-warning-alert [title]="'Nessuna persona da mostrare'">
      <div class="tw-text-sm tw-mt-1">
        Chiedi ad un admin di abilitare una lista per poter registrare le presenze
      </div>
    </app-dea-warning-alert>
  </div>
  <!-- Event Details -->
  <app-event-details-box *ngIf="!loading && event" [event]="event"></app-event-details-box>
  <!-- Event with No-Attendees -->
  <div *ngIf="!loading && event && attendees.length === 0"
    class="w-100 tw-flex tw-justify-center tw-mt-20 tw-opacity-50">
    <app-dea-warning-alert [title]="'Nessuna persona da mostrare'">
      <div class="tw-text-sm tw-mt-1">
        Chiedi ad un admin di aggiungere dei partecipanti a questo evento per poter registrare le presenze
      </div>
    </app-dea-warning-alert>
  </div>
  <!-- Event with No-Attendees -->
  <!-- Event Attendees Listing -->
  <div *ngIf="!loading" class="tw-flex tw-flex-col tw-w-full tw-gap-6">
    <div *ngFor="let attendee of attendees; let i = index;"
      class="tw-flex tw-flex-col tw-border tw-rounded tw-p-4 tw-gap-1">
      <div class="tw-flex tw-flex-row tw-justify-between">
        <div>{{attendee.lastname}} {{attendee.firstname}}</div>
        <div>
          <p-checkbox [(ngModel)]="attendee.status" [binary]="true" inputId="binary" class="tw-w-full"
            (click)="setIndexAndPatch(i)">
          </p-checkbox>
        </div>
      </div>
      <div>{{attendee.email}}</div>
      <div>{{attendee.phone}}</div>
      <div *ngIf="attendee.metadata">
        <hr class="tw-my-3 -tw-mx-2">
      </div>
      <div *ngIf="attendee.metadata" class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2">
        <div *ngFor="let item of attendee.metadata | keyvalue">
          <span>{{item.key | toTitleCase}}</span>: {{item.value}}
        </div>
      </div>
    </div>
  </div>
</app-dea-container>