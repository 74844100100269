<app-dea-full-screen-logo-wrapper [subtitle]="'Registro Presenze'" [showHomepageBtn]="true">
  <form class="tw-max-w-full p-fluid tw-flex tw-flex-col tw-gap-4" [formGroup]="pinForm">
    <div class="field">
      <p-password type="text" placeholder="PIN" formControlName="password" [disabled]=true [feedback]="false"
        [toggleMask]="true">
      </p-password>
    </div>
    <div class="tw-grid tw-grid-cols-3 sm:tw-grid-cols-6 tw-gap-4 tw-w-full">
      <p-button *ngFor="let i of btnBlocks" label="{{i}}" (onClick)="addCharacter(i)"
        styleClass="p-button-outlined p-button-secondary" class="tw-w-full" class="tw-p-0">
      </p-button>
    </div>
    <div *ngIf="error?.error?.message" class="fs075rem tw-text-red-500 -tw-mt-2 tw-ms-2">
      {{error?.error?.message}}
    </div>
    <div class="tw-flex tw-flex-row tw-gap-4">
      <p-button label="Annulla" styleClass="p-button-outlined" class="tw-w-full" title="Annulla"
        (onClick)="clearCharacters()">
      </p-button>
      <p-button label="Accedi" class="tw-w-full" title="Accedi" (onClick)="submit()" [loading]="loading"></p-button>
    </div>
  </form>
</app-dea-full-screen-logo-wrapper>