<app-navbar></app-navbar>
<app-dea-spinner *ngIf="loading" [sizePx]="'32'"></app-dea-spinner>
<app-dea-container *ngIf="!loading">
  <!-- TOOLS MENU -->
  <div class="tw-flex tw-flex-row tw-justify-between tw-mb-6">
    <div (click)="backToDashboard()" title="Dashboard"
      class="fs075rem tw-flex tw-flex-row tw-items-center tw-flex-nowrap hover:tw-underline tw-cursor-pointer">
      <lucide-icon name="chevron-left"></lucide-icon>
    </div>
    <div class="tw-flex tw-flex-row tw-justify-end tw-gap-8 md:tw-gap-6">
      <div (click)="editEventDetails()" title="Modifica Dettagli"
        class="fs075rem tw-flex tw-flex-row tw-items-center tw-flex-wrap tw-gap-2 hover:tw-underline tw-cursor-pointer">
        <lucide-icon name="pencil" [size]="14"></lucide-icon> <span class="tw-hidden md:tw-block">
          Modifica Dettagli
        </span>
      </div>
      <!-- <div title="Carica da Ring"
        class="fs075rem tw-flex tw-flex-row tw-items-center tw-flex-wrap tw-gap-2 hover:tw-underline tw-cursor-pointer">
        <lucide-icon name="folder-input" [size]="14"></lucide-icon> <span class="tw-hidden md:tw-block">
          Carica da Ring
        </span>
      </div> -->
      <div title="Carica CSV" (click)="uploadCSV()"
        class="fs075rem tw-flex tw-flex-row tw-items-center tw-flex-wrap tw-gap-2 hover:tw-underline tw-cursor-pointer">
        <lucide-icon name="file-up" [size]="14"></lucide-icon> <span class="tw-hidden md:tw-block">
          Carica CSV
        </span>
      </div>
      <div title="Scarica CSV" (click)="downloadCSV()"
        class="fs075rem tw-flex tw-flex-row tw-items-center tw-flex-wrap tw-gap-2 hover:tw-underline tw-cursor-pointer">
        <lucide-icon name="file-down" [size]="14"></lucide-icon> <span class="tw-hidden md:tw-block">
          Scarica CSV
        </span>
      </div>
      <div title="Elimina Evento" (click)="deleteEvent()"
        class="fs075rem tw-flex tw-flex-row tw-items-center tw-flex-wrap tw-gap-2 hover:tw-underline tw-cursor-pointer">
        <lucide-icon name="trash-2" [size]="14"></lucide-icon> <span class="tw-hidden md:tw-block">
          Elimina Evento
        </span>
      </div>
    </div>
  </div>
  <hr class="tw-my-3 tw-opacity-75 -tw-mx-8">
  <!-- TOOLS MENU -->
  <!-- EVENT DETAILS -->
  <app-event-details-box [event]="event" [showLine]="false" [showSwitch]="true"></app-event-details-box>
  <!-- EVENT DETAILS -->
  <!-- EVENT ATTENDEES LISTING -->
  <p-table [value]="event?.attendees || []" [tableStyle]="{'min-width': '50rem'}">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th>Stato</th>
        <th>Cognome</th>
        <th>Nome</th>
        <th>E-Mail</th>
        <th>Telefono</th>
        <th *ngFor="let metadata of (event?.attendees || []).at(0)?.metadata| keyvalue;">{{metadata.key | toTitleCase}}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-attendee let-columns="columns">
      <tr>
        <td>
          <span *ngIf="attendee?.status === false"><lucide-icon name="x-circle"></lucide-icon></span>
          <span *ngIf="attendee?.status === true"><lucide-icon name="check-circle-2"></lucide-icon></span>
          <span *ngIf="attendee?.status === undefined"><lucide-icon name="help-circle"></lucide-icon></span>
        </td>
        <td>{{attendee.firstname}}</td>
        <td>{{attendee.lastname}}</td>
        <td>{{attendee.email}}</td>
        <td>{{attendee.phone}}</td>
        <td *ngFor="let metadata of (event?.attendees || []).at(0)?.metadata| keyvalue;">{{metadata.value}}</td>
      </tr>
    </ng-template>
  </p-table>
  <!-- EVENT ATTENDEES LISTING -->
</app-dea-container>