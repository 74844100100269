import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dea-warning-alert',
  templateUrl: './dea-warning-alert.component.html',
  styleUrls: ['./dea-warning-alert.component.scss'],
})
export class DeaWarningAlertComponent {
  @Input() title: string = '';
  @Input() subtitle: string = '';
}
