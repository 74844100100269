import { ClientStorageService } from 'src/app/services/client-storage.service';
import { EventService } from 'src/app/services/event.service';
import { StorageKeys } from 'src/app/constants/storege-keys';
import { Component, OnInit, inject } from '@angular/core';
import { Headquarter } from 'src/app/models/headquarter';
import { Event } from 'src/app/models/event.model';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  private _eventService: EventService = inject(EventService);
  private _clientStorageService = inject(ClientStorageService);

  public headquarters: string[] = Headquarter.getFrontendHeadqarters();
  public selectedHeadquarter: string = Headquarter.MILANO.frontend;
  public event?: Event;

  ngOnInit(): void {
    this.selectedHeadquarter = this._clientStorageService.setHeadquarter(
      localStorage.getItem(StorageKeys.HEADQUARTER)
    );
    this._eventService.getPublicEvent().subscribe({
      next: (data: any) => {
        this.event = new Event(data.data);
      },
      error: (err: any) => {
        this.event = undefined;
        console.error('Get Public Event: ', err);
      },
    });
  }
}
