import { AuthService } from 'src/app/services/auth.service';
import { AppPaths } from 'src/app/constants/app-paths';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent {
  constructor(private _authService: AuthService, private _router: Router) {}

  ngOnInit(): void {
    this._authService
      .logout()
      .subscribe()
      .add(() => {
        this._authService.isAdmin = false;
        this._authService.isLogged = false;
        this._router.navigateByUrl(AppPaths.SESSION);
      });
  }
}
