import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Component } from '@angular/core';

@Component({
  selector: 'app-dea-delete-confirm-dialog',
  templateUrl: './dea-delete-confirm-dialog.component.html',
  styleUrls: ['./dea-delete-confirm-dialog.component.scss'],
})
export class DeaDeleteConfirmDialogComponent {
  public messages?: string[];

  constructor(
    public ref: DynamicDialogRef,
    private _dynamicDialogConfig: DynamicDialogConfig
  ) {
    this.messages = this._dynamicDialogConfig.data.messages || [
      'Confermi di voler eliminare questo elemento?',
    ];
  }

  close(value: boolean) {
    this.ref.close(value);
  }
}
