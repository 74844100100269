<div
  class="tw-flex tw-justify-between tw-items-center tw-w-full tw-h-12 tw-px-4 tw-sticky tw-top-0 tw-shadow tw-bg-white">
  <div class="tw-flex tw-justify-between tw-items-center tw-w-full">
    <div>
      <img src="https://static.deamicis.cloud/logo/dea.webp" alt="DeA" width="32" height="32">
    </div>
    <div class="tw-flex tw-flex-row tw-justify-end tw-gap-5">
      <div *ngIf="!isAdmin" class="hover:tw-cursor-pointer" routerLink="/">Home</div>
      <div *ngIf="isAdmin" class="hover:tw-cursor-pointer" routerLink="/dashboard">Dashboard</div>
      <div *ngIf="isAdmin" class="hover:tw-cursor-pointer" routerLink="/presenze">Presenze</div>
      <div class="hover:tw-cursor-pointer" routerLink="/logout" title="Logout">
        <lucide-icon name="log-out" [strokeWidth]="1" class="hover:tw-text-red-600"></lucide-icon>
      </div>
    </div>
  </div>
</div>