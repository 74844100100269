import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgOptimizedImage } from '@angular/common';
// PROVIDERS
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoggerInterceptor } from './utils/logger-interceptor';
import { AuthInterceptor } from './utils/auth-interceptor';
// PRIME-NG
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { TimelineModule } from 'primeng/timeline';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { DividerModule } from 'primeng/divider';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DialogModule } from 'primeng/dialog';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FileUploadModule } from 'primeng/fileupload';
// PAGES and COMPONENTS
import { DeaFullScreenLogoWrapperComponent } from './components/dea-pack/dea-full-screen-logo-wrapper/dea-full-screen-logo-wrapper.component';
import { DeaSpinnerComponent } from './components/dea-pack/dea-spinner/dea-spinner.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { SessionComponent } from './pages/session/session.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PresenzeComponent } from './pages/presenze/presenze.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { PinComponent } from './pages/pin/pin.component';
import { DeaContainerComponent } from './components/dea-pack/dea-container/dea-container.component';
import { EventCardComponent } from './components/event-card/event-card.component';
import { DeaWarningAlertComponent } from './components/dea-pack/dea-warning-alert/dea-warning-alert.component';
import { SessionErrorComponent } from './pages/session-error/session-error.component';
import { UserCellComponent } from './components/user-cell/user-cell.component';
import { EventDetailsComponent } from './pages/dashboard/event-details/event-details.component';
import { EventDialogComponent } from './components/event-dialog/event-dialog.component';
import { EventDetailsBoxComponent } from './components/event-details-box/event-details-box.component';
// PIPES
import { ToTitleCasePipe } from './pipes/to-title-case.pipe';
import { ToItDateDdMmmmYyyyPipe } from './pipes/to-it-date-dd-mmmm-yyyy.pipe';

// ICONS
import {
  LucideAngularModule,
  AlertTriangle,
  ChevronRight,
  CheckCircle2,
  FolderInput,
  ChevronLeft,
  PlusCircle,
  HelpCircle,
  FileDown,
  XCircle,
  FileUp,
  Trash2,
  Pencil,
  LogOut,
  Plus,
  Star,
} from 'lucide-angular';
import { DeaDeleteConfirmDialogComponent } from './components/dea-pack/dea-delete-confirm-dialog/dea-delete-confirm-dialog.component';
import { MessageService } from 'primeng/api';
import { UploadCsvDialogComponent } from './pages/dashboard/event-details/upload-csv-dialog/upload-csv-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    DeaSpinnerComponent,
    DeaFullScreenLogoWrapperComponent,
    LoginComponent,
    LogoutComponent,
    NavbarComponent,
    SessionComponent,
    DashboardComponent,
    PresenzeComponent,
    HomepageComponent,
    NotFoundComponent,
    PinComponent,
    DeaContainerComponent,
    EventCardComponent,
    DeaWarningAlertComponent,
    SessionErrorComponent,
    UserCellComponent,
    ToTitleCasePipe,
    ToItDateDdMmmmYyyyPipe,
    EventDetailsComponent,
    EventDialogComponent,
    EventDetailsBoxComponent,
    DeaDeleteConfirmDialogComponent,
    UploadCsvDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgOptimizedImage,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ButtonModule,
    TimelineModule,
    CalendarModule,
    InputTextModule,
    PasswordModule,
    DividerModule,
    CheckboxModule,
    InputTextareaModule,
    DialogModule,
    DynamicDialogModule,
    CardModule,
    TableModule,
    ToastModule,
    InputSwitchModule,
    FileUploadModule,
    LucideAngularModule.pick({
      AlertTriangle,
      ChevronRight,
      CheckCircle2,
      FolderInput,
      ChevronLeft,
      PlusCircle,
      HelpCircle,
      FileDown,
      XCircle,
      FileUp,
      Trash2,
      Pencil,
      LogOut,
      Plus,
      Star,
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoggerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    DialogService,
    MessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
