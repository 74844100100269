import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { AppPaths } from '../constants/app-paths';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpInterceptor,
} from '@angular/common/http';

export const retryCount = 5;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService, private _router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (
          error.status === 401 &&
          !error.url.includes(AppPaths.LOGIN) &&
          !error.url.includes('credential')
        ) {
          if (!error.url.includes('token')) {
            return this.reAuthenticate().pipe(
              switchMap(() => next.handle(request))
            );
          }
        }
        return throwError(() => {
          if (error.status === 401) {
            this._router.navigateByUrl(AppPaths.SESSION);
          }
          return error;
        });
      })
    );
  }

  reAuthenticate(): Observable<any> {
    return this._authService.token();
  }
}
