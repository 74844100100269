import { ApiResponse } from './api-response.model';

class ApiErrorObj {
  code?: number;
  title?: string;
  message?: string;
  details?: string;
}

export class ApiError extends ApiResponse {
  error: ApiErrorObj;

  constructor(json: any) {
    super(json);
    this.error = new ApiErrorObj();
    this.error.code = json['error']['error']['code'];
    this.error.title = json['error']['error']['title'];
    this.error.message = json['error']['error']['message'];
    this.error.details = json['error']['error']['details'];
  }
}
