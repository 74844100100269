import { StorageKeys } from 'src/app/constants/storege-keys';
import { Headquarter } from '../models/headquarter';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ClientStorageService {
  constructor() {}

  getHeadquarter(): Headquarter {
    let headquarterStr: string | null = localStorage.getItem(
      StorageKeys.HEADQUARTER
    );
    headquarterStr = (headquarterStr ?? Headquarter.MILANO.backend).toString();
    switch (headquarterStr) {
      case Headquarter.GORGONZOLA.backend:
        return Headquarter.GORGONZOLA;
      default:
        return Headquarter.MILANO;
    }
  }

  getHeadquarterString(): string {
    return (
      Headquarter.fromString(
        localStorage.getItem(StorageKeys.HEADQUARTER) || undefined
      )?.frontend ?? Headquarter.MILANO.frontend
    ).toString();
  }

  setHeadquarter(value: string | null): string {
    switch (value) {
      case Headquarter.GORGONZOLA.backend:
        localStorage.setItem(
          StorageKeys.HEADQUARTER,
          Headquarter.GORGONZOLA.backend
        );
        break;
      default:
        localStorage.setItem(
          StorageKeys.HEADQUARTER,
          Headquarter.MILANO.backend
        );
        break;
    }
    return this.getHeadquarterString();
  }
}
