<div class="tw-flex tw-flex-col tw-w-full tw-gap-3 tw-mb-6">
  <div class="tw-flex tw-justify-between">
    <div class="tw-flex tw-flex-col tw-w-full">
      <div class="tw-font-semibold">{{event?.title}}</div>
      <div>{{event?.subtitle}}</div>
      <div class="tw-mt-2">{{event?.description}}</div>
    </div>
    <div class="tw-flex tw-flex-col tw-gap-2">
      <div class="tw-w-40 tw-text-end">
        <div class="tw-flex-nowrap">{{event?.date | toItDateDdMmmmYyyy}}</div>
      </div>
      <div *ngIf="showSwitch" class="tw-w-full tw-text-end">
        <p-inputSwitch inputId="publicEventInputSwitch" title="Evento Pubblico (On/Off)"
          (onChange)="onSwitchChanged($event)" [ngModel]="switchValue">
        </p-inputSwitch>
      </div>
    </div>
  </div>
  <hr *ngIf="showLine" class="tw-my-3 tw-opacity-75">
</div>