import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EventService } from 'src/app/services/event.service';
import { Event } from 'src/app/models/event.model';
import { Component, inject } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-upload-csv-dialog',
  templateUrl: './upload-csv-dialog.component.html',
  styleUrls: ['./upload-csv-dialog.component.scss'],
})
export class UploadCsvDialogComponent {
  private _messageService: MessageService = inject(MessageService);
  private _eventService: EventService = inject(EventService);
  loading: boolean = false;
  event?: Event;

  reset: boolean = false;
  file?: any;

  constructor(
    public ref: DynamicDialogRef,
    private _dynamicDialogConfig: DynamicDialogConfig
  ) {
    this.event = this._dynamicDialogConfig?.data?.event || undefined;
  }

  onFileSelect(event: any) {
    if (event?.files?.length > 0) this.file = event.files[0];
  }

  onResetSwitch(event: any) {
    this.reset = event?.checked;
  }

  postCSV(): void {
    const formData: FormData = new FormData();
    formData.append('csv', this.file, new Date().toISOString());
    this._eventService
      .postCSV(this.event?.id || 'ID-ERROR', formData, this.reset)
      .subscribe({
        next: (data: any) => {
          this._messageService.add({
            key: 'app-toast',
            severity: 'success',
            summary: 'Ok!',
            detail: 'CSV caricato correttamente.',
          });
          this.close(true);
        },
        error: (error) => {
          console.error('Error: ', error);
          this._messageService.add({
            key: 'app-toast',
            severity: 'error',
            summary: 'Attenzione:',
            detail:
              'Sembra si sia verificato un errore in fase di caricamento del file CSV, verifica la struttura del file e riprova più tardi.',
          });
          this.close();
        },
      });
  }

  close(value: boolean = false): void {
    this.ref.close(value);
  }
}
