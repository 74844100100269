<div class="tw-fixed tw-z-10 tw-w-full tw-text-center tw-mt-[92vh] tw-opacity-20 hover:tw-underline"
  style="font-size: .5rem;">
  <a href="http://www.gabrielebellani.com" target="_blank" rel="noopener noreferrer">Made with ♥︎ by G</a>
</div>
<div
  class="tw-w-screen tw-h-screen tw-min-h-fit tw-min-w-fit tw-flex tw-justify-center tw-items-center tw-px-4 tw-py-8">
  <div
    class="tw-max-w-lg tw-px-6 tw-z-20 tw-border-l-4 tw-border-deaOrange tw-bg-white tw-rounded-r tw-py-6 tw-flex tw-flex-col tw-gap-4 tw-p-4">
    <div *ngIf="showHomepageBtn" class="tw-font-medium tw-text-base tw-opacity-60 hover:tw-cursor-pointer"
      style="font-size: 0.65rem;" routerLink="/">
      <span class="pi pi-chevron-left tw-opacity-70" style="font-size: 0.65rem;"></span> Homepage
    </div>
    <img ngOptimizedImage class="tw-cursor-pointer" src="https://static.deamicis.cloud/logo/dea-logo-b.png"
      alt="Istituti E. De Amicis" routerLink="/" title="Homepage" loading="eager">
    <div *ngIf="showSubtitle" class="tw-font-medium tw-text-base">
      {{subtitle}}
    </div>
    <div class="tw-flex tw-flex-col tw-gap-4">
      <ng-content></ng-content>
    </div>
  </div>
</div>