import { AuthService } from 'src/app/services/auth.service';
import { Component, inject } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  private _authService: AuthService = inject(AuthService);
  isAdmin: boolean = false;

  constructor() {
    this.isAdmin = this._authService.isAdmin || false;
  }
}
