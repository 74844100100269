import { environment } from 'src/environments/environment';

export class API {
  // AUTH
  static login(): string {
    return `${environment.authUrl}/api/login`;
  }
  static logout(): string {
    return `${environment.authUrl}/api/logout`;
  }
  static token(): string {
    return `${environment.authUrl}/api/token`;
  }
  static session(): string {
    return `${environment.authUrl}/api/session`;
  }
  // STATIC
  static deAmicisRingIcon(): string {
    return `${environment.staticUrl}/ring/ring-icon-b.png`;
  }
  static defaultThumbnail(): string {
    return `${environment.staticUrl}/user/thumbnail.png`;
  }
  static deaLogo(): string {
    return `${environment.staticUrl}/logo/dea.webp`;
  }
  // EVENT
  static events(): string {
    return `${environment.apiUrl}/v1/events/listing`;
  }
  static event(): string {
    return `${environment.apiUrl}/v1/event`;
  }
  static eventId(id?: string): string {
    return `${environment.apiUrl}/v1/event/${id || 'ID-ERROR'}`;
  }
  static eventCSV(id?: string, reset?: boolean): string {
    return `${environment.apiUrl}/v1/event/${id || 'ID-ERROR'}/csv?reset=${
      reset?.toString() === 'true' ? 'true' : 'false'
    }`;
  }
  static patchPublicValueEvent(id?: string): string {
    return `${environment.apiUrl}/v1/event/${id || 'ID-ERROR'}/public`;
  }
  // EVENT-PUBLIC
  static publicEvent(): string {
    return `${environment.apiUrl}/v1/event/public`;
  }
  static publicEventDetails(): string {
    return `${environment.apiUrl}/v1/event/public/details`;
  }
  // SOCKET
  static evnetSocket(): string {
    return `${environment.apiUrl}/v1/events/public/socket`;
  }
}
