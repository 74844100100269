import { AppPaths } from 'src/app/constants/app-paths';
import { Component } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  linkAction: string = AppPaths.SESSION;

  constructor() {}
}
