import { DeaDeleteConfirmDialogComponent } from 'src/app/components/dea-pack/dea-delete-confirm-dialog/dea-delete-confirm-dialog.component';
import { EventDialogComponent } from 'src/app/components/event-dialog/event-dialog.component';
import { UploadCsvDialogComponent } from './upload-csv-dialog/upload-csv-dialog.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ApiError } from 'src/app/models/api/api-error.model';
import { EventService } from 'src/app/services/event.service';
import { Component, OnInit, inject } from '@angular/core';
import { Event } from 'src/app/models/event.model';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Location } from '@angular/common';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
})
export class EventDetailsComponent implements OnInit {
  private _messageService: MessageService = inject(MessageService);
  private _dialogService: DialogService = inject(DialogService);
  private _eventService: EventService = inject(EventService);
  private _route: ActivatedRoute = inject(ActivatedRoute);
  private _location: Location = inject(Location);
  ref: DynamicDialogRef | undefined;

  loading: boolean = true;
  error?: ApiError;
  event?: Event;

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      if (params['id']) this.event = new Event({ _id: params['id'] });
    });
    this.getData();
  }

  async getData() {
    this.loading = true;
    this.error = undefined;
    this._eventService
      .getEventDetails(this.event?.id)
      .subscribe({
        next: (data: any) => {
          this.event = new Event(data.data);
        },
        error: (error: any) => {
          this.event = undefined;
          this.error = new ApiError(error);
          console.error('GetEventDetails Error:', error);
        },
      })
      .add(() => (this.loading = false));
  }

  editEventDetails() {
    this.ref = this._dialogService.open(EventDialogComponent, {
      width: '95vw',
      maximizable: false,
      header: 'Modifica Evento',
      style: { 'max-width': '500px' },
      baseZIndex: 10000,
      dismissableMask: true,
      closeOnEscape: true,
      closable: false,
      modal: true,
      data: { event: this.event },
    });

    this.ref.onClose.subscribe((value: boolean) => {
      if (value != undefined) {
        value ? this.getData() : console.error('Errore');
      }
    });
  }

  uploadCSV() {
    this.ref = this._dialogService.open(UploadCsvDialogComponent, {
      width: '95vw',
      maximizable: false,
      header: 'Carica Elenco CSV',
      style: { 'max-width': '500px' },
      baseZIndex: 10000,
      dismissableMask: true,
      closeOnEscape: true,
      closable: false,
      modal: true,
      data: { event: this.event },
    });

    this.ref.onClose.subscribe((value: boolean) => {
      if (value != undefined && value) this.getData();
    });
  }

  downloadCSV() {
    this._eventService.getCSV(this.event?.id || 'NO-ID').subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let a = document.createElement('a');
      a.download =
        res.headers.get('originalname') ||
        `${new Date().toISOString()} - De Amicis Ring Event.csv`;
      a.href = window.URL.createObjectURL(blob);
      a.click();
    });
  }

  deleteEvent(): void {
    this.ref = this._dialogService.open(DeaDeleteConfirmDialogComponent, {
      width: '95vw',
      maximizable: false,
      header: 'Elimina Evento',
      style: { 'max-width': '500px' },
      baseZIndex: 10000,
      dismissableMask: true,
      closeOnEscape: true,
      closable: false,
      modal: true,
      data: {
        messages: [
          "Attenzione: tutti i dati relativi all'evento e alla relativa lista delle persone registrate per questo evento andranno perse e non saranno più recuperabili.",
          'Confermi di voler eliminare questo evento e tutti i dati relativi?',
        ],
      },
    });

    this.ref.onClose.subscribe((value: boolean) => {
      if (value != undefined) {
        if (value) {
          this._eventService.deleteEvent(this.event?.id).subscribe({
            next: (_: any) => {
              this._messageService.add({
                key: 'app-toast',
                severity: 'success',
                summary: 'Ok!',
                detail: 'Evento eliminato con successo.',
              });
              this.backToDashboard();
            },
            error: (error: any) => {
              console.error('Delete Event Error:', error);
              this._messageService.add({
                key: 'app-toast',
                severity: 'error',
                summary: 'Attenzione:',
                detail:
                  "Si è verificato un errore in fase di eleimnazione dell'elemento, riprova più tardi.",
              });
            },
          });
        } else {
          this._messageService.add({
            key: 'app-toast',
            severity: 'info',
            summary: 'Info:',
            detail: "Operazione di eliminazione annullata dall'utente",
          });
        }
      }
    });
  }

  backToDashboard(): void {
    this._location.back();
  }
}
