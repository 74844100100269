import { Component } from '@angular/core';

@Component({
  selector: 'app-dea-container',
  templateUrl: './dea-container.component.html',
  styleUrls: ['./dea-container.component.scss']
})
export class DeaContainerComponent {

}
