import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dea-full-screen-logo-wrapper',
  templateUrl: './dea-full-screen-logo-wrapper.component.html',
  styleUrls: ['./dea-full-screen-logo-wrapper.component.scss'],
})
export class DeaFullScreenLogoWrapperComponent {
  @Input() subtitle: string = 'Events Management System';
  @Input() showHomepageBtn: boolean = false;
  @Input() showSubtitle: boolean = true;
}
